import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import LockIcon from "@assets/icons/lock.svg";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  ButtonContainer,
  ContentContainer,
} from "../summary/styles/summary.style";
import { TotalZakatSummaryContainer } from "@Zakat/components/ZakatSummary/styled/styled";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@ifgengineering/component-library";
import { CurrencyContext } from "@components/CurrencyContext";
import {
  fetchZakatAsync,
  updateZakatPaymentAmount,
} from "@Zakat/store/zakatV2/zakat.action";
import {
  nisabSelector,
  zakatSelector,
  zakatTopUpSelector,
} from "@Zakat/store/zakatV2/zakat.selector";
import { charitySelector } from "@Zakat/store/charity/charity.selector";
import styled from "styled-components";
import ZakatSummary from "@Zakat/components/ZakatSummary";
import {
  CharityItemContainer,
  CharityName,
  CharityValueContainer,
  HeadingText,
  SmallText,
  AllocationCharityContainer,
} from "@Zakat/pages/payment/styles/payment.style";
import useAnalytics from "@helpers/useAnalytics";
import { Header } from "./styles/payment.style";
import { getZakatSummary } from "../../helpers/zakat/zakatV2Helpers";

const PageWrapper = styled.div`
  padding: 5vh 10vw;
`;

const PaymentSummary: React.FC = () => {
  const dispatch = useDispatch();
  const charity = useSelector(charitySelector);
  const zakat = useSelector(zakatSelector);
  const nisab = useSelector(nisabSelector);
  const topUp = useSelector(zakatTopUpSelector) || 0;
  const zakatAmountDue = zakat?.amountDue || 0;

  const { formatCurrency } = useContext(CurrencyContext);
  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView("zakat:paymentSummary_pageViewed");
  }, []);

  useEffect(() => {
    dispatch(fetchZakatAsync());
  }, []);

  const handleConfirm = () => {
    navigate("/app/dua");
  };

  const charityAllocationAmount = zakatAmountDue * (charity.allocation / 100);
  const totalPayableAmount = Number((zakatAmountDue + topUp).toFixed(2));

  useEffect(() => {
    dispatch(updateZakatPaymentAmount(totalPayableAmount));
  }, [totalPayableAmount]);

  // zakat amount + top up x %charity allocation x 25% = giftAid amount
  const getGiftAidPrice = () => {
    if (!zakat) return 0;
    return totalPayableAmount * (charity.allocation / 100) * 0.25;
  };

  const submitButtonIsActive = Boolean(totalPayableAmount);

  const summary = getZakatSummary(zakat, nisab);

  return (
    <PageWrapper>
      <ContentContainer>
        <Header>Complete Summary</Header>
        <ZakatSummary formatCurrency={formatCurrency} summary={summary} />
        {topUp ? (
          <TotalZakatSummaryContainer small>
            <HeadingText type="H16" color={"BLUE900"}>
              Zakat Topup
            </HeadingText>
            <HeadingText type="H20" color={"BLUE900"}>
              {formatCurrency(topUp)}
            </HeadingText>
          </TotalZakatSummaryContainer>
        ) : null}
        <TotalZakatSummaryContainer small>
          <HeadingText type="H16" color={"BLUE900"}>
            Charity Allocation
          </HeadingText>
          <AllocationCharityContainer>
            <HeadingText type="H20" color={"BLUE900"}>
              {formatCurrency(zakatAmountDue)}
            </HeadingText>
            <SmallText type="P16" color={"BLUE900"}>
              ({charity.allocation}% of total zakat due)
            </SmallText>
          </AllocationCharityContainer>
          {charity.allocation && (
            <CharityItemContainer>
              <CharityName type="P16" color={"BLUE900"}>
                {charity.displayName}
              </CharityName>
              <CharityValueContainer>
                <SmallText type="P16" color={"BLUE900"}>
                  {charity.allocation}% of zakat
                </SmallText>
                {charity.allocation && (
                  <SmallText type="P16" color={"BLUE900"}>
                    {formatCurrency(charityAllocationAmount)}
                  </SmallText>
                )}
              </CharityValueContainer>
            </CharityItemContainer>
          )}
        </TotalZakatSummaryContainer>
        {zakat?.enableGiftAid && (
          <TotalZakatSummaryContainer
            color="#F9F8F3"
            borderColor="#DCC67A"
            small
          >
            <HeadingText type="H16" color={"BLUE900"}>
              Gift Aid
            </HeadingText>
            <HeadingText type="H20" color={"BLUE900"}>
              {formatCurrency(getGiftAidPrice())}
            </HeadingText>
          </TotalZakatSummaryContainer>
        )}
        <TotalZakatSummaryContainer
          color="#E6F1E4"
          borderColor="#458731"
          small
          spaceBetween
        >
          <Text type="H20" color={"BLUE900"}>
            Total to pay
          </Text>
          <Text type="H20" color={"BLUE900"}>
            {formatCurrency(totalPayableAmount)}
          </Text>
        </TotalZakatSummaryContainer>

        <ButtonContainer>
          {!submitButtonIsActive && (
            <Text type="P16" color="ERROR800">
              The payment amount cannot be £0.
            </Text>
          )}
          <FormButton
            text="Next"
            disclaimerIcon={<LockIcon />}
            leftArrowLabel="Back"
            leftArrowOnClick={() => navigate("/app/gift-aid")}
            onClick={handleConfirm}
            active={submitButtonIsActive}
          />
        </ButtonContainer>
      </ContentContainer>
    </PageWrapper>
  );
};

export default PaymentSummary;
